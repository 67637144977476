import React from "react";

import "./index.scss";
import { ITask } from "../hooks";
import { ClaimButton } from "./claim-button";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../consts";
import { formatValues } from "../../../utils";
import { ZoomInAnimation } from "../../page-animation";
import { useNavigate } from "react-router-dom";
import { PayPassMultiplierComponent } from "../pay-pass-multiplier";

interface IProps extends ITask {
  payPassMultiplier?: number;
  onClaim?: () => void;
  canClaim?: boolean;
  onOpenModal?: () => void;
  onSetShowVerifyModal?: (value: boolean) => void;
  onSetShowShareModal?: () => void;
}

export const TaskItem: React.FC<IProps> = ({
  payPassMultiplier,
  icon,
  stringIcon,
  componentIcon,
  title,
  subtitle,
  reward,
  is_done,
  additionalRewardInfo,
  canClaim,
  verifyLink,
  share,
  onOpenModal,
  onClaim,
  openPageBeforeTaskReadyType,
  claimTask,
  doubleVerify,
  onSetShowVerifyModal,
  onSetShowShareModal,
}) => {
  const navigate = useNavigate();

  function openPageBeforeTaskReady(path: string) {
    navigate(path);
  }

  const onClick = React.useMemo(() => {
    if (share) {
      return () => onSetShowShareModal?.();
    }

    if (claimTask) {
      if (openPageBeforeTaskReadyType && !canClaim && !is_done) {
        return () => openPageBeforeTaskReady(openPageBeforeTaskReadyType);
      }
    } else if (doubleVerify) {
      return () => onSetShowVerifyModal?.(true);
    } else if (!!verifyLink) {
      return () => onOpenModal?.();
    } else {
      return onClaim;
    }
  }, [
    canClaim,
    claimTask,
    doubleVerify,
    is_done,
    onClaim,
    onOpenModal,
    onSetShowShareModal,
    onSetShowVerifyModal,
    openPageBeforeTaskReady,
    openPageBeforeTaskReadyType,
    share,
    verifyLink,
  ]);

  const getIcon = () => {
    if (stringIcon) {
      return <p style={{ fontSize: 50, color: "#fff" }}>{stringIcon}</p>;
    } else if (icon) {
      return <img src={icon} alt="" style={{width: '65px'}} />;
    }

    return componentIcon;
  };

  return (
    <div
      onClick={onClick}
      className={`${is_done ? "btn-disabled" : ""} task-link-wrapper`}
    >
      <div
        className="task-item-avatar blur-bg-strong"
        style={{ minWidth: "88px" }}
      >
        {canClaim && !is_done && <ClaimButton onClick={() => onClaim?.()} />}

        {canClaim && !is_done ? (
          getIcon()
        ) : (
          <ZoomInAnimation> {getIcon()} </ZoomInAnimation>
        )}
      </div>
      <div className="task-item-info-stack">
        <p className="green-color">{is_done ? title : title}</p>
        <p className="green-color">{is_done ? subtitle : subtitle}</p>

        {is_done ? (
          <p className="common-color">Completed</p>
        ) : (
          <div className="task-item-reward">
            <div className="green-color">{additionalRewardInfo}</div>
            <SmallCoin
              width={BALANCE_COIN_SIZE_SMALL}
              height={BALANCE_COIN_SIZE_SMALL}
            />
            <p className="common-color">+{formatValues(reward)}</p> <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
          </div>
        )}
      </div>
    </div>
  );
};
