import React from "react";

import "./index.scss";

import { ProgressBar } from "react-loader-spinner";
import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { TELEGRAM_LINK } from "../../consts";
import { Spinner } from "../spinner";
// import { MainLogo } from "../../img/main-logo";

// const mainBg = require("../../img/main-bg.png") as string;
const mainBg = require("./main-bg.png") as string;

// const contestBg = require("./contest-bg.jpg") as string;
// const collabBg = require("./collagBG.png") as string;
// const collabLabel = require("./collabLabel.svg").default as string;
// const chooseIcon = require("./choose.png") as string;
// const runeWcoinIcon = require("./rune-w-coin.png") as string;
// const runeIcon = require("./rune.png") as string;
// const runeDescIcon = require("./ryne-desc.png") as string;
// const addWalletIcon = require("./add-wallet-icon.png") as string;
// const addWalletDescIcon = require("./add-wallet-desc.svg").default as string;

const tonWcoinIcon = require("./text-min.png") as string;

// const contentContest = require("./tgIcon.png") as string;
const tgIcon = require("./tgIcon.png") as string;
const x_social = require("./x.png") as string;

interface IProps extends React.PropsWithChildren {
  isLoading: boolean;
  isFetching?: boolean;
  bg?: string;
  customBg?: string;
  isMainPage?: boolean;
  hasLoadedOnce?: boolean;
  setHasLoadedOnce?: React.Dispatch<React.SetStateAction<boolean>>;
  isTasks?: boolean;
}

export const Template: React.FC<IProps> = ({
  isLoading,
  isFetching,
  children,
  bg,
  customBg,
  isMainPage,
  hasLoadedOnce,
  setHasLoadedOnce,
  isTasks,
}) => {
  const webApp = useWebApp();

  const handleOpenTelegram = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  };

  const handleOpenX = () => {
    webApp.openLink("https://x.com/wcoin_io?s=21");
  };

  const [isLoadingState, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isMainPage && !hasLoadedOnce) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
        setHasLoadedOnce?.(true);
      }, 3000);

      return () => clearInterval(timer);
    }
  }, [isMainPage, hasLoadedOnce, setHasLoadedOnce]);

  const showChildren = () => {
    if (isMainPage) {
      return isLoadingState ? null : children;
    }

    return children;
  };

  const getBG = () => {
    if (isTasks) {
      return isLoadingState || isLoading ? mainBg : bg;
    }

    return isLoadingState || isLoading ? bg : customBg || mainBg;
  };

  return (
    <div
      className="template-body"
      style={{
        backgroundImage: `url(${getBG()})`,
        backgroundSize: "cover",
        backgroundColor: "#000"
      }}
    >
      <CSSTransition
        in={isLoadingState || (isMainPage && isLoading)}
        timeout={200}
        classNames="fade1"
        unmountOnExit
      >
        <div
          className="overlay"
          style={{
            backgroundImage: `url(${getBG()})`,
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                alignItems: "center",
                color: "#fff",
                marginTop: "30px",
              }}
            >
              {/* Add after rune*/}
              {/* <MainLogo />
              <div style={{display: 'flex', justifyContent: 'center', gap:"4px" }}>
                <img src={chooseIcon} alt="" width="290px" />
              </div> */}

              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "57px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img src={tonWcoinIcon} width="300px" alt="" />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      borderRadius: "20px",
                      border: "2px solid #A392FA",
                      padding: "6px 12px",
                      justifyContent: "space-around",
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <img
                      style={{ color: "#A392FA" }}
                      width={18}
                      height={15}
                      src={tgIcon}
                      alt=""
                      onClick={handleOpenTelegram}
                    />
                    <div
                      style={{
                        height: "15px",
                        color: "#fff",
                        width: "1px",
                        backgroundColor: "#A392FA",
                      }}
                    ></div>
                    <img
                      style={{ color: "#A392FA" }}
                      width={15}
                      height={15}
                      src={x_social}
                      alt=""
                      onClick={handleOpenX}
                    />
                  </div>
                  <ProgressBar
                    visible={true}
                    height="80"
                    width="80"
                    barColor="rgb(151, 71, 255)"
                    borderColor="#A392FA"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              </div>
            </div>
            <img
              src={getBG()}
              alt=""
              style={{ width: "-webkit-fill-available", height: "101vh", backgroundPosition: "center" }}
            />
          </div>
        </div>
      </CSSTransition>

      {isFetching && <Spinner visible={isFetching} />}
      {showChildren()}
    </div>
  );
};
