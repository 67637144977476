import React from "react";

import {
  CHAIN,
  SendTransactionRequest,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { Address, toNano } from "@ton/ton";
import { beginCell } from "@ton/ton";

import { UserContext } from "../contexts/user-context";
import { useUpdateUser } from "../../hooks";
import { PAY_PAY_REWARD } from "./consts";
import { getCurrentUTCDateTime } from "../../utils";
import { PAY_PASS_MULTIPLIER } from "../../consts";
import { LottieAnimationContext } from "../contexts/lottie-animation-context";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

export const usePayPassApi = () => {
  const RECIPIENT_ADDRESS = process.env.REACT_APP_PAY_PASS || '';
  const AMOUNT = process.env.REACT_APP_PAY_PASS_AMOUNT || '';

  // Context
  const { isLoading, user, setClaimState } = React.useContext(UserContext);
  const { setIsVisible } = React.useContext(LottieAnimationContext);
  const [impactOccurred] = useHapticFeedback();

  // Wallets connections
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  const isNetNotAccepted = React.useMemo(() => wallet?.account?.chain === CHAIN.TESTNET ,[wallet?.account?.chain]);

  // States
  const [showError, setShowError] = React.useState<boolean>(false);
  const [showNetError, setShowNetError] = React.useState<boolean>(false);

  const [showSuccessPayPassModal, setShowSuccessPayPassModal] =
    React.useState<boolean>(false);


  // Update handler
  const [updateUser, updateState] = useUpdateUser();

  const [isPreparing, setIsPreparing] = React.useState(false)

  // Consts
  const claimState = user?.claim_state;
  const databaseUserId = user?.database_user?.id;

  const PAYLOAD_BODY = beginCell()
    .storeUint(0, 32)
    .storeStringTail(`payPass: ${databaseUserId || ""}`)
    .endCell();

  const address = wallet?.account?.address;

  const handleDisconnect = async () => {
    try {
      await tonConnectUI.disconnect();
    } catch (e) {
      console.log("Error during disconnect:", e);
    }
  };
  

  React.useEffect(() => {
    if (isNetNotAccepted) {
      handleDisconnect();
      setShowNetError(true);
    }

  } ,[handleDisconnect, isNetNotAccepted])
  
  const handleUpdateDb = (boc: string) => {
    if (isNetNotAccepted) {
      handleDisconnect();
      setShowNetError(true);
    }

    if (claimState && databaseUserId && address) {
      const payPass = {
        senderAddress: {
          bounceableAddress: address,
          unbounceableAddress: Address.parse(address).toString({
            bounceable: false,
          }),
        },
        recipientAddress: RECIPIENT_ADDRESS,
        date: getCurrentUTCDateTime(),
        amount: AMOUNT,
        boc,
      };

      const boosts = claimState?.boosts
        ? { ...claimState.boosts, payPass }
        : { payPass };

      const newData = {
        id: databaseUserId,
        boosts,
        balance: claimState.balance + PAY_PAY_REWARD,
      };

      updateUser({ ...newData, id: databaseUserId })
        .then(() => {
          // @ts-ignore
          setClaimState?.((prevState) => ({
            ...(prevState || {}),
            ...newData,
            payPassMultiplier: PAY_PASS_MULTIPLIER,
          }));

          setShowSuccessPayPassModal(true);
          impactOccurred("heavy");
        })
        .catch((e: any) => {
          console.warn(e);
          impactOccurred("heavy");
        })
        .finally(() => {
          setIsPreparing(false);
          impactOccurred("heavy");
        });
    }
  };

  const handleSentTransaction = async () => {
    if (!wallet || !wallet.account?.address) {
      console.error("Wallet not connected");
      return;
    }

    const transaction: SendTransactionRequest = {
      validUntil: Math.floor(Date.now() / 1000) + 3600,
      messages: [
        {
          address: RECIPIENT_ADDRESS,
          amount: toNano(AMOUNT).toString(),
          payload: PAYLOAD_BODY.toBoc().toString("base64"),
        },
      ],
    };

    try {
      if (claimState && databaseUserId && address) {
        setIsPreparing(true);
        const response = await tonConnectUI.sendTransaction(transaction);
  
        if (response) {
          handleUpdateDb(response?.boc || 'empty boc');
        }
      }

    } catch (error) {
      setIsPreparing(false);
      setShowError(true);
    }
  };

  const handleCloseSuccessPayPassModal = () => {
    impactOccurred("heavy");
    setShowSuccessPayPassModal(false);
    setIsVisible?.(true);
  }

  return {
    isWalletConnected: tonConnectUI.connected,
    isFetching: updateState.isFetching,
    disabled: updateState.isFetching || isPreparing,
    address: wallet?.account?.address,
    onDisconnect: handleDisconnect,
    onSentTransaction: handleSentTransaction,
    showError,
    setShowError,
    isLoading,
    showSuccessPayPassModal,
    onCloseSuccessPayPassModal: handleCloseSuccessPayPassModal,
    isPayPassPurchased: !!user?.claim_state?.boosts?.payPass,
    showNetError,
    setShowNetError,
  };
};
