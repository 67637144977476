import React from "react";
import { TGroupTask } from "../type";
import { ROUTES } from "../../routers";
import { ETask, ITask } from "../hooks";
import { TaskItem } from "../task-item";
import { useNavigate } from "react-router-dom";
import { TAppUser } from "../../contexts/types";
import { ERune, TRune } from "../../hooks/rune/models";

const oIcon =
  require("../../../components/hooks/rune/img/o-icon.png") as string;

interface IProps {
  payPassMultiplier?: number;
  walletTask: ITask;
  threeFriendsTask: ITask;
  taskList: TGroupTask;
  onClaimTask: (ct: string | null) => Promise<void>;
  onChangeCurrentIndexTask: (ct: string | null) => void;
  readyToClaim: {};
  isEmpty?: boolean;
  user?: TAppUser;
  onSetRuneStep?: (runeStep: TRune) => void;
  isCompleted?: boolean;
  onSetShowVerifyModal?: (value: boolean) => void;
  onSetShowShareModal?: () => void;
}

export const TaskList: React.FC<IProps> = ({
  threeFriendsTask,
  walletTask,
  taskList,
  onClaimTask,
  onChangeCurrentIndexTask,
  readyToClaim,
  isEmpty,
  user,
  onSetRuneStep,
  isCompleted,
  onSetShowVerifyModal,
  onSetShowShareModal,
  payPassMultiplier,
}) => {
  const navigate = useNavigate();

  const openPage = (path: string, state?: any) => {
    navigate(path, state);
  };

  // @ts-ignore
  const runeStep = user?.claim_state?.tasks?.rune?.step === ERune.C;

  return (
    <div className={`new-task-group-wrapper ${isEmpty && "task-list-empty"}`} style={{position: 'relative'}} >
      {runeStep && isCompleted && (
        <img
          src={oIcon}
          onClick={() =>
            onSetRuneStep?.({
              step: ERune.O,
              completed: false,
            })
          }
          alt=""
          className="c-rune-icon c-rune-icon-task"
        />
      )}
      {isEmpty ? (
        <p className="empty-list-description">
          The list of completed tasks is empty
        </p>
      ) : (
        Object.keys(taskList)
          .sort((a, b) => {
            if (a === "Specials") return -1;
            if (b === "Specials") return 1;
            return a.localeCompare(b);
          })
          .map((category) => {
            if (taskList[category]?.length) {
              return (
                <>
                  <div
                    className="plain-text task-list-header-text green-color new-task-group-wrapper-label"
                    style={{ fontWeight: 600 }}
                  >
                    {category}
                  </div>
                  {taskList[category].map((task, index) => {
                     if (task.type === ETask.INVITE_THREE_MATE) {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            openPage(ROUTES.MATES.PATH, {
                              state: { inviteThreeMatesData: true },
                            })
                          }
                          style={
                            task.is_done
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : undefined
                          }
                        >
                          <TaskItem key={index} payPassMultiplier={payPassMultiplier} {...threeFriendsTask}  />
                        </div>
                      );
                    } else if (task.type === ETask.CONNECT_WALLET) {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            openPage(ROUTES.WALLET.PATH)
                          }
                          style={
                            task.is_done
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : undefined
                          }
                        >
                          <TaskItem key={index} payPassMultiplier={payPassMultiplier} {...walletTask} />
                        </div>
                      );
                  } else {
                      return (
                        <TaskItem
                          key={index}
                          payPassMultiplier={payPassMultiplier}
                          // @ts-ignore
                          canClaim={readyToClaim[task.type] || false}
                          {...task}
                          onClaim={() => onClaimTask(task.type)}
                          onOpenModal={() =>
                            onChangeCurrentIndexTask(task.type)
                          }
                          onSetShowVerifyModal={() => {
                            onChangeCurrentIndexTask(task.type);
                            onSetShowVerifyModal?.(true);
                          }}
                          onSetShowShareModal={
                            () => {
                              onChangeCurrentIndexTask(task.type)
                               onSetShowShareModal?.();
                            }
                          }
                        />
                      );
                    }
                  })}
                </>
              );
            } else {
              return null;
            }
          })
      )}
    </div>
  );
};
