import React from "react";

import { CSSTransition } from "react-transition-group";

import "../index.scss";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { BALANCE_COIN_SIZE_MEDIUM, TELEGRAM_LINK } from "../../../consts";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { PayPassMultiplierComponent } from "../../page-tasks/pay-pass-multiplier";

interface IProps {
  amount: number | null;
  onClose: () => void;

}

export const MatesModal: React.FC<IProps> = ({ amount, onClose }) => {
  const webApp = useWebApp();

  const handleOpenTelegram = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  };

  return (
    <CSSTransition in={!!amount} timeout={400} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="close-modal ab" onClick={onClose}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
            <div className="task-modal-icon">
            <p style={{ fontSize: 108 }}>{'🤝'}</p>

              </div>
              
              <div className="task-modal-reward ab">
                <SmallCoin width={BALANCE_COIN_SIZE_MEDIUM} height={BALANCE_COIN_SIZE_MEDIUM} /> 
                <p className="ab">+{amount}</p>
              </div>
              <p className="task-modal-received">Received</p>
            </div>
            <div className="task-modal-button-wrapper">
              <button
                onClick={handleOpenTelegram}
                className={`app-modal-content-button`}
              >
                Follow W-Community
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
