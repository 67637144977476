import React from "react";

import { UserContext } from "../contexts/user-context";
import { TClaimState } from "../contexts/types";
import { AnimationContext } from "../contexts/animation-context";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routers";
import { getCurrentTimestamp } from "../page-tasks/utils";
import { useUpdateUser } from "../../hooks";

const worker = require("./img/worker.png") as string;

const dice = require("./img/dice.png") as string;
const multiplier = require("./img/multiplier.png") as string;

const MIN_WORKER_MINUTES = 3 * 60;
const MAX_WORKER_MINUTES = 12 * 60;
export const MAX_WORKER_REWARD = 170_000;

export const usePageClaimApi = () => {
  const {
    user,
    isLoading,
    isFetching,
    isSuccess,
    setClaimState,
    onOpenNotification,
    setDebouncedValue,
    workerModalStatus,
    setWorkerModalStatus,
    setHasLoadedOnce,
    hasLoadedOnce,
    canShowContent,
    task: { activeTaskCount },
  } = React.useContext(UserContext);
  const navigate = useNavigate();
  const payPassMultiplier =  user?.claim_state?.payPassMultiplier;

  const [earnByMinute, setEarnByMinute] = React.useState<null | number>(null);

  React.useEffect(() => {
    if (
      typeof earnByMinute !== "number" &&
      user?.claim_state?.boosts?.worker?.last_claimed_at
    ) {
      const last_claimed_at =
        user?.claim_state?.boosts?.worker?.last_claimed_at;
      const currentTimestamp = getCurrentTimestamp();

      if (last_claimed_at) {
        const secondsPerMinute = 60;
        let minutesPassed = Math.floor(
          (currentTimestamp - last_claimed_at) / secondsPerMinute
        );

        if (minutesPassed > MAX_WORKER_MINUTES) {
          minutesPassed = MAX_WORKER_MINUTES;
        }

        if (
          workerModalStatus?.readyToShowWorkerModal &&
          !workerModalStatus?.alreadyOpenedModal &&
          !workerModalStatus?.showModal
          && minutesPassed >= MIN_WORKER_MINUTES
        ) {
          const earn =
            (MAX_WORKER_REWARD / MAX_WORKER_MINUTES) * minutesPassed;
            setEarnByMinute(Math.round(earn));
        }
      }
    }
  }, [
    earnByMinute,
    user?.claim_state?.boosts?.worker?.last_claimed_at,
    workerModalStatus?.alreadyOpenedModal,
    workerModalStatus?.readyToShowWorkerModal,
    workerModalStatus?.showModal,
  ]);

  const { handleActiveAnimation } = React.useContext(AnimationContext);
  const [updateUser, updateUserState] = useUpdateUser();

  const claimState = user?.claim_state;

  const [diceBoost, setDiceBoost] = React.useState(1);
  const isDiceBoostActive = diceBoost > 1;

  const [isMultiplierBoostActive, setIsMultiplierBoostActive] =
    React.useState(false);

  const [addToBalance, setAddToBalance] = React.useState<number>(0);

  React.useEffect(() => {
    if (
      claimState?.boosts?.lucky_dice &&
      !claimState?.boosts?.lucky_dice?.is_used &&
      diceBoost === 1 &&
      user.database_user && 
      isSuccess
    ) {
      setDiceBoost(claimState?.boosts?.lucky_dice.value);
      handleActiveAnimation?.({
        duration: 10,
        blockCount: 35,
        blockImage: dice,
      });

      setTimeout(() => {
        setDiceBoost(1);
      }, 10000);

      const boosts = {
        ...claimState.boosts,
        lucky_dice: {
          ...claimState!.boosts!.lucky_dice,
          value: 1,
          is_used: true,
        },
      };

      const newData = {
        boosts,
      };
      // TODO: complete
      updateUser({ id: user.database_user.id, ...newData }).then(() => {
        // @ts-ignore
        setClaimState?.((prevState) => ({ ...prevState, ...newData }));
      }).catch((e: any) => {
        console.warn(e);
      });
    }
  }, [claimState?.boosts?.lucky_dice, isSuccess]);

  // Increase balance with duration
  const [counter, setCounter] = React.useState<
    { start: number; end: number }
  >({ start: 0, end: 0 });

  React.useEffect(() => {
      if (claimState?.balance && claimState?.balance !== counter.end) {
      setCounter({
        start: claimState.balance,
        end: claimState.balance,
      });
    }
  }, [claimState?.balance, counter]);

  // useEffect needs to update all additional parameters
  React.useEffect(() => {
    // update refill
    handleRefillUpdate();
  }, [claimState]);

  //  ----- update all additional parameters ------
  function handleRefillUpdate() {
    if (
      claimState &&
      claimState.energy < claimState?.max_energy
    ) {
      const now = new Date();
      const utcTimestampInSeconds = Math.floor(now.getTime() / 1000);
      const secondsPassed = utcTimestampInSeconds - claimState.last_click_at;
      const energyRefill = Math.floor(
        claimState.energy_refill_multiplier * secondsPassed
      );

      const newEnergy =
        claimState.energy + energyRefill > claimState.max_energy
          ? claimState.max_energy
          : claimState.energy + energyRefill;

      if (energyRefill > 0) {
        const newLocalClaimState = {
          energy: newEnergy,
          last_click_at: utcTimestampInSeconds,
        };

        // @ts-ignore
        setClaimState?.({ ...claimState, ...newLocalClaimState });
      }
    }
  }

  // Energy refill
  const energyRefill = React.useRef<any>();
  React.useEffect(() => {
    if (
      claimState &&
      !energyRefill.current &&
      claimState!.energy < claimState?.max_energy
    ) {
      // The amount of energy + refill_multiplier should not exceed max_energy
      const nextEnergy =
        claimState!.energy + claimState.energy_refill_multiplier >
        claimState?.max_energy
          ? claimState?.max_energy
          : claimState!.energy + claimState.energy_refill_multiplier;
      energyRefill.current = setInterval(() => {
        // @ts-ignore
        setClaimState((prevState) => ({ ...prevState, energy: nextEnergy }));
      }, 1000);
    }

    return () => {
      clearInterval(energyRefill.current);
      energyRefill.current = null;
    };
  }, [claimState]);

  // Handle click
  const handleClickChange = () => {
    // check if energy is enough
    if (disableBtn) return;

    const now = new Date();
    const utcTimestampInSeconds = Math.floor(now.getTime() / 1000);

    const getNewEnergy = () => {
      return isDiceBoostActive ? claimState!.energy : Math.max(0, claimState!.energy - claimState!.click_multiplier);
    };

    const newClicksValue = claimState!.clicks + 1;

    let multiplierBoost = 1;
    setIsMultiplierBoostActive(false);
    if (claimState?.boosts?.multiplier?.clicks_when_bought) {
      if (
        (claimState!.clicks -
          claimState?.boosts?.multiplier?.clicks_when_bought) %
          100 ===
        0
      ) {
        setIsMultiplierBoostActive(true);
        multiplierBoost = 100;
        handleActiveAnimation?.({
          duration: 2,
          blockCount: 15,
          blockImage: multiplier,
        });
      }
    }

    const newAddToBalance = claimState!.click_multiplier * diceBoost * multiplierBoost * payPassMultiplier!;

    setAddToBalance(newAddToBalance);

    const newLocalClaimState = {
      clicks: newClicksValue,
      energy: getNewEnergy(),
      balance: claimState!.balance + newAddToBalance,
      balance_from_clicks: claimState?.balance_from_clicks
        ? claimState.balance_from_clicks + newAddToBalance
        : newAddToBalance,
      last_click_at: utcTimestampInSeconds,
    } as TClaimState;

    setClaimState?.(
      (prevState) => ({ ...prevState, ...newLocalClaimState })
    );
    setCounter({ start: claimState!.balance, end: newLocalClaimState.balance });
    setDebouncedValue?.(newLocalClaimState);
  };

  const disableBtn = React.useMemo(() => {
    if (claimState) {
      return claimState.energy < claimState.click_multiplier || claimState.energy === 0;
    }
    return true;
  }, [claimState]);

  const onGoToDashboard = () => {
    if (claimState?.stakes?.length) {
      navigate(ROUTES.DASHBOARD.PATH, { state: { stayHerePage: true } });
    } else {
      navigate(ROUTES.STAKING.PATH);
    }
  };

  const handleCloseModal = () => {
    setWorkerModalStatus?.((prevState) => ({
      ...prevState,
      alreadyOpenedModal: true,
    }));
  };

  const handleWorkerClaimBalance = async () => {
        if (
          earnByMinute &&
          user.database_user &&
          typeof claimState?.balance === "number" && 
          typeof claimState.balance_from_clicks === "number"
        ) {
          const workerBoost = {
            worker: { last_claimed_at: getCurrentTimestamp() },
          };
    
          const newBoosts = claimState.boosts
            ? {
                ...claimState.boosts,
                ...workerBoost,
              }
            : workerBoost;
    
          const newData = {
           
            balance: claimState.balance + earnByMinute,
            boosts: newBoosts,
            balance_from_clicks: claimState.balance_from_clicks + earnByMinute,
          };
    
          if (user?.claim_state?.id) {
          // TODO: complete
          // @ts-ignore
          updateUser({ id: user.claim_state.id,  ...newData})
          .then(() => {
            setCounter({ start: claimState!.balance, end: newData.balance });
            // @ts-ignore
            setClaimState?.((prevState) => ({ ...prevState, ...newData }));
            handleCloseModal();
            setEarnByMinute(null);
            handleActiveAnimation?.({
              duration: 2,
              blockCount: 15,
              blockImage: worker,
            });
          })
          .catch((e: any) => {
            console.warn(e);
          });
            }  
        }

  };

  return {
    isLoading: isLoading,
    isSuccess,
    isFetching: updateUserState.isLoading || isFetching,
    updateUserState,
    balance: user?.claim_state?.balance,
    energy: user?.claim_state?.energy ?? 0,
    maxEnergy: user?.claim_state?.max_energy ?? 0,
    user,
    onClickChange: handleClickChange,
    claimState,
    counter,
    onOpenNotification,
    disableBtn,
    setClaimState,
    isDiceBoostActive,
    isMultiplierBoostActive,
    addToBalance,
    onGoToDashboard,
    workerModalStatus,
    setWorkerModalStatus,
    earnByMinute,
    setEarnByMinute,
    onCloseModal: handleCloseModal,
    onWorkerClaimBalance: handleWorkerClaimBalance,
    activeTaskCount,
    setHasLoadedOnce,
    hasLoadedOnce,
    canShowContent,
    payPassMultiplier,
  };
};
