import React from "react";

import { TonConnectButton } from "@tonconnect/ui-react";
import { Address } from "@ton/core";

import { BottomAnimation } from "../../page-animation";
import { truncateString } from "../../wallet/utils";

import "./styles.css";
const editIcon = require("./img/edit-icon.png") as string;

interface IProps {
  address?: string;
  isWalletConnected: boolean;
  onSentTransaction: () => Promise<void>;
  onDisconnect: () => Promise<void>;
  disabled: boolean;
}

export const WalletActions: React.FC<IProps> = ({
  isWalletConnected,
  onSentTransaction,
  address,
  onDisconnect,
  disabled,
}) => {
  const btnDisabled = disabled ? "tn-disabled" : "";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <BottomAnimation>
        {isWalletConnected ? (
          <div
            className={`main-wrapper-actions ${btnDisabled}`}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "90px",
            }}
          >
            <div className="wallet-actions-wrapper">
              {address && (
                <div className="connected-address">
                  <p className="white-color">
                    Connected address:{" "}
                    {truncateString(
                      Address.parse(address).toString({
                        bounceable: false,
                      }),
                      3
                    )}
                  </p>
                  <img src={editIcon} alt="" onClick={onDisconnect} />
                </div>
              )}
              <div style={{ width: "327.84px", height: "63.9px" }}>
                <div
                  onClick={disabled ? undefined : onSentTransaction}
                  className="wai-action-text-timer-claim ab"
                >
                  {disabled ? "PROCESSING..." : "Explore for 1 TON"}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={`wai-action-text-timer-claim wallet-connect-btn-paypass-wrapper ${btnDisabled} ab`}>
              <TonConnectButton className={`wallet-connect-btn wallet-connect-btn-paypass ab`} />
            </div>
          </>
        )}
      </BottomAnimation>
    </div>
  );
};
