import React from 'react';
import { GOLD_COLOR, PAY_PASS_MULTIPLIER } from '../../../consts';

interface IProps {
    payPassMultiplier?: number;
}

export const PayPassMultiplierComponent: React.FC<IProps> = ({payPassMultiplier}) => {
    const s = {
        color: GOLD_COLOR,
    }

    return payPassMultiplier === PAY_PASS_MULTIPLIER ? <p style={s}>x{PAY_PASS_MULTIPLIER}</p> : null;
}