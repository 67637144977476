import React from "react";

import { WebAppUser, useInitData } from "@vkruglikov/react-telegram-web-app";
import { skipToken } from "@reduxjs/toolkit/query";

import { useAuthMutation, TUser, useRegistrationAppMutation, TRegistrationApp } from "../feature/users";
import { TClaimState, TUserContext } from "./types";
import { useClickDebounce } from "../hooks";
import { TimestampError } from "../timestamp-error";
import { ESKins } from "../page-boosters/boosters/main-boosters/boosters-items/w-skins/types";
import { usePageTasksApi } from "../page-tasks/hooks";
import { useUpdateUser } from "../../hooks";
import { useRuneApi } from "../hooks/rune";
import { RuneModal } from "../app-modal/rune-modal";
import { defineUserName } from "./utils";
import { JWT_TOKEN_KEY } from "../../services";
import { DEFAULT_MULTIPLIER, PAY_PASS_MULTIPLIER } from "../../consts";

export const LOCAL_STORAGE_KEY = "claim_state";
const errorMessage = 'Invalid identifier or password';

type TAuthError = {
   data: {
    error: {
      message: string;
    }
   }
}

export const UserContext = React.createContext<TUserContext>({
  user: {
    telegram_user: undefined,
    database_user: undefined,
    claim_state: undefined,
  },
  isLoading: false,
  isError: false,
  isSuccess: false,
  setClaimState: undefined,
  onOpenNotification: undefined,
  setDebouncedValue: undefined,
  workerModalStatus: undefined,
  setWorkerModalStatus: undefined,
  setIsTimestampError: undefined,
  onSetRuneStep: undefined,
  // @ts-ignore
  task: {},
  canShowContent: false,
});

interface IError {
  data: {
    code: number;
  };
}

export const UserProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // Getting the current user from the telegram app or using a mock user
  const [initDataUnsafe, initData] = useInitData();
  const telegramUser: WebAppUser = initDataUnsafe?.user as WebAppUser;

  // Load hooks
  const [auth, authState] = useAuthMutation();
  const [updateUser, updateUserState] = useUpdateUser();
  const [registrationApp, registrationAppState] =  useRegistrationAppMutation();

  const [isAuthFetched, setIsAuthFetched] = React.useState(false);

  const canGetUser = (authState.isSuccess || registrationAppState.isSuccess) ? undefined : skipToken;

  const databaseUser: TUser | undefined = authState?.data?.user;

  React.useEffect(() => {
    if (
      telegramUser?.id ||
      (process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_EMAIL &&
        process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_PASSWORD)
    ) {
      try {
        auth({
          username: telegramUser?.id?.toString(),
          password: telegramUser?.id?.toString(),
          // @ts-ignore
          hash: initData,
        })
          .unwrap()
          .then((res) => {
            console.log('res-success');
          })
          .catch((e: TAuthError) => {

            // @ts-ignore
            const referral_code =  window?.Telegram?.WebApp?.initDataUnsafe?.start_param
            
            if (!isAuthFetched && e?.data?.error?.message === errorMessage) {
                const resData: TRegistrationApp = {
                  username: initDataUnsafe?.user?.id ? initDataUnsafe.user.id.toString() : "",
                  telegram_username:  `${defineUserName(telegramUser?.first_name || '')} ${defineUserName(telegramUser?.first_name || '')}`,
                  real_telegram_username:  telegramUser?.username || '',
                  // @ts-ignore
                  is_premium:  telegramUser?.is_premium || false,
                  chat_id:  initDataUnsafe?.user?.id ? initDataUnsafe.user.id.toString() : "",
                  raw_telegram_user:  {
                      // @ts-ignore
                      id: telegramUser?.id ? telegramUser.id : '',
                      is_bot: false,
                      first_name: telegramUser?.first_name || '',
                      last_name: telegramUser?.last_name || '',
                      username: telegramUser?.username || '',
                      language_code: telegramUser?.language_code || '',
                    },
                  referral_code: referral_code ?? '',
                }

                registrationApp(resData).then(res => {
                  // @ts-ignore
                  if (res?.data?.user) {
                    // @ts-ignore
                    const userData: TUser = res.data?.user as TUser;
                    // @ts-ignore
                    const jwtToken: string = res?.data?.jwt as string;
              
                    if (userData && jwtToken) {
                      setUserState(true, userData);
                      window.localStorage.setItem(JWT_TOKEN_KEY, jwtToken);
  
                      setIsAuthFetched(true);
                    }
                  }
                });
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [telegramUser?.id, auth]);

  // Initialize local claimState
  const [claimState, setClaimState] = React.useState<TClaimState | undefined>(
    undefined
  );

  // Worker modal
  const [workerModalStatus, setWorkerModalStatus] = React.useState<{
    readyToShowWorkerModal: boolean;
    showModal: boolean;
    alreadyOpenedModal: boolean;
  }>({
    readyToShowWorkerModal: false,
    showModal: false,
    alreadyOpenedModal: false,
  });



  React.useEffect(() => {
    if (
      (updateUserState.isSuccess || registrationAppState.isSuccess) &&
      !workerModalStatus.readyToShowWorkerModal &&
      (authState.isSuccess || registrationAppState.isSuccess) &&
      isAuthFetched &&
      claimState?.last_visited_at
    ) {
      const showWorkerModal = setTimeout(() => {
        setWorkerModalStatus((prevState) => ({
          ...prevState,
          readyToShowWorkerModal: true,
        }));
      }, 2000);

      return () => {
        clearInterval(showWorkerModal);
      };
    }
  }, [authState.isSuccess, isAuthFetched, updateUserState.isSuccess, registrationAppState.isSuccess, workerModalStatus.readyToShowWorkerModal, claimState?.last_visited_at]);

  // TODO: написать респонс из updateUser
  React.useEffect(() => {
    if (authState.isSuccess && !isAuthFetched) {
      setUserState(true, authState.data?.user);
      setIsAuthFetched(true);
    }
  }, [authState.isSuccess]);

  function setUserState(needToUpdateDB: boolean, userState?: TUser) {
    const currentDate = new Date();
    const currentTimestamp = Math.floor(currentDate.getTime() / 1000);

    try {
      if (userState) {
        const rewardFromNewMates = userState?.reward_from_new_mates
          ? Number(userState?.reward_from_new_mates)
          : 0;

        let newClaimState: TClaimState = {
          id: userState?.id,
          username: userState?.username,
          telegram_username: userState?.telegram_username,
          click_multiplier: Number(userState.click_multiplier),
          click_multiplier_level: Number(userState.click_multiplier_level),
          current_mates_level: Number(userState?.current_mates_level),
          max_energy: Number(userState.max_energy),
          max_energy_level: Number(userState?.max_energy_level),
          energy_refill_multiplier: Number(userState.energy_refill_multiplier),
          energy_refill_multiplier_level: Number(
            userState.energy_refill_multiplier_level
          ),
          balance: Number(userState.balance) + rewardFromNewMates,
          clicks: Number(userState.clicks),
          energy: Number(userState.energy),
          last_click_at: Number(userState.last_click_at),
          claims: userState?.claims,
          balance_from_clicks:
            Number(userState?.balance_from_clicks) + rewardFromNewMates,
          is_telegram_joined: userState?.is_telegram_joined,
          selected_blockchain: userState?.selected_blockchain,
          is_x_joined: userState?.is_x_joined,
          task_invite_mate_claimed: userState?.task_invite_mate_claimed,
          is_website_opened: userState?.is_website_opened,
          task_join_community_claimed: userState?.task_join_community_claimed,
          task_join_hub_claimed: userState?.task_join_hub_claimed,
          task_upgrade_charger_to_max_claimed:
            userState?.task_upgrade_charger_to_max_claimed,
          task_try_staking_claimed: userState?.task_try_staking_claimed,
          task_stake_level: Number(userState?.task_stake_level),
          task_click_level: Number(userState?.task_click_level),
          boosts: userState?.boosts,
          stakes: userState?.stakes,
          task_visit_every_day: userState?.task_visit_every_day,
          newMates: undefined,
          reward_from_new_mates: 0,
          task_balance_level: Number(userState?.task_balance_level),
          task_yescoin_joined: userState?.task_yescoin_joined,
          last_visited_at: needToUpdateDB
            ? currentTimestamp
            : userState?.last_visited_at
            ? Number(userState.last_visited_at)
            : undefined,
          raw_telegram_user: userState?.raw_telegram_user,
          task_is_premium_claimed: userState?.task_is_premium_claimed,
          tasks: userState?.tasks || {},
          skins: userState?.skins || {
            [ESKins.DEFAULT]: {
              purchased: false,
              selected: true,
              type: ESKins.DEFAULT,
            },
          },
          payPassMultiplier: userState?.boosts?.payPass ? PAY_PASS_MULTIPLIER : DEFAULT_MULTIPLIER,
        };

        let array = Object.entries(newClaimState).filter(
          ([key, value]) => value !== undefined
        );
        let filteredNewClaimState = array.reduce((obj, [key, value]) => {
          // @ts-ignore
          obj[key] = value;
          return obj;
        }, {} as TClaimState);

        setClaimState?.((prevState) => ({
          ...(prevState || {}),
          ...filteredNewClaimState,
        }));

        if (needToUpdateDB || rewardFromNewMates > 0) {
          updateUser({
            id: userState.id,
            balance: newClaimState.balance,
            reward_from_new_mates: 0,
            last_visited_at: currentTimestamp,
            tasks: newClaimState.tasks,
          }).catch((e: any) => {
            console.warn(e);
          });
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  // Clear local storage
  React.useEffect(() => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  }, []);

  const user = React.useMemo(
    () => ({
      telegram_user: telegramUser,
      database_user: databaseUser,
      claim_state: claimState,
    }),
    [claimState, databaseUser, telegramUser]
  );

  const { setDebouncedValue } = useClickDebounce(user, setClaimState);
  const [isTimestampError, setIsTimestampError] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (authState.error && (authState.error as IError)?.data?.code === 101) {
      setIsTimestampError(true);
    }

    if (
      updateUserState.isError &&
      (updateUserState.error as IError)?.data?.code === 101
    ) {
      setIsTimestampError(true);
    }
  }, [authState.error, updateUserState]);

  const {
    isLoading: isTaskLoading,
    isSuccess,
    user: userContext,
    tasks,
    onChangeCurrentIndexTask,
    currentTask,
    onClaimTask,
    isFetching,
    WALLET_TASK,
    THREE_FRIENDS_TASK,
    readyToClaim,
    isJoinedToTelegram,
    onTelegramGroupVerify,
  } = usePageTasksApi({
    user,
    isLoading: authState.isLoading || updateUserState.isLoading || registrationAppState.isLoading,
    isSuccess: authState.isSuccess || registrationAppState.isSuccess,
    setClaimState: setClaimState,
    canGetUser,
  });

  const {
    onShowRuneModal,
    showRuneModal,
    stepRuneModalContent,
    rune,
    onSetRuneStep,
    isRuneUpdating,
  } = useRuneApi({
    tasks: claimState?.tasks,
    setClaimState,
    claimState: user?.claim_state,
  });

  const activeTaskCount = React.useMemo(() => {
    return tasks.reduce((acc, task) => {
      if (
        typeof task.isReadyToClaim === "boolean" &&
        task.isReadyToClaim === true &&
        !task.is_done
      ) {
        acc += 1;
      } else {
        if (!task.is_done && task.isReadyToClaim === true) {
          acc += 1;
        } else {
          if (typeof task.isReadyToClaim === "undefined" && !task.is_done) {
            acc += 1;
          }
        }
      }

      return acc;
    }, 0);
  }, [tasks]);

  const [hasLoadedOnce, setHasLoadedOnce] = React.useState(false);

  // @ts-ignore
  const ctx: TUserContext = React.useMemo(
    () => ({
      canShowContent: true,
      user,
      setClaimState: setClaimState,
      isLoading:
        (isAuthFetched ? false : authState.isLoading || isTaskLoading) ||
        updateUserState.isLoading || isTaskLoading  || registrationAppState.isLoading,
      isSuccess,
      isFetching: isAuthFetched ? authState.isLoading : false,
      setDebouncedValue,
      workerModalStatus,
      setWorkerModalStatus,
      setIsTimestampError,
      setHasLoadedOnce,
      hasLoadedOnce,
      onSetRuneStep,
      task: {
        isLoading: isTaskLoading,
        user: userContext,
        tasks,
        onChangeCurrentIndexTask,
        currentTask,
        onClaimTask,
        isFetching,
        WALLET_TASK,
        THREE_FRIENDS_TASK,
        readyToClaim,
        isJoinedToTelegram,
        activeTaskCount,
        onTelegramGroupVerify,
      },
    }),
    [user, isAuthFetched, authState.isLoading, isTaskLoading, updateUserState.isLoading, registrationAppState.isLoading, isSuccess, setDebouncedValue, workerModalStatus, hasLoadedOnce, onSetRuneStep, userContext, tasks, onChangeCurrentIndexTask, currentTask, onClaimTask, isFetching, WALLET_TASK, THREE_FRIENDS_TASK, readyToClaim, isJoinedToTelegram, activeTaskCount, onTelegramGroupVerify]
  );

  const getContent = () => {
    if (isTimestampError) {
      return <TimestampError />;
    }

    return children;
  };

  return (
    <UserContext.Provider value={ctx}>
      <>
        <RuneModal
          show={showRuneModal}
          rune={rune}
          onClick={onShowRuneModal}
          content={stepRuneModalContent}
          onSetRuneStep={onSetRuneStep}
          isLoading={isRuneUpdating}
        />
        {getContent()}
      </>
    </UserContext.Provider>
  );
};
