import React from "react";

import { EBooster } from "./boosters/types";
import { MULTIPLIER_UPGRADES, UPGRADES, WORKER_UPGRADES } from "./boosters/consts";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routers";
import { UserContext } from "../contexts/user-context";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { getCurrentTimestamp } from "../page-tasks/utils";
import { useUpdateUser } from "../../hooks";
import { DAILY_BOOSTERS_TIMES } from "../contexts/consts";

export const usePageBoostersApi = () => {
  const { user, isLoading, setClaimState, onSetRuneStep} = React.useContext(UserContext);
  const [impactOccurred] = useHapticFeedback();
  const payPassMultiplier =  user?.claim_state?.payPassMultiplier;

  const claimState = user?.claim_state;

  const [updateUser, updateState] = useUpdateUser();

  const navigate = useNavigate();

  // COUNTER START
  // Increase balance with duration
  const [counter, setCounter] = React.useState<
    { start: number; end: number }
  >({ start: 0, end: 0 });

  React.useEffect(() => {
    if (claimState?.balance && claimState?.balance !== counter.end) {
    setCounter({
      start: claimState.balance,
      end: claimState.balance,
    });
  }
}, [claimState?.balance, counter]);
  // COUNTER END

  const [currentType, setCurrentType] = React.useState<EBooster | null>(null);

  const handleModalClose = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    setCurrentType(null);
  };

  const handleModalOpen = (booster: EBooster) => {
    impactOccurred("heavy");
    setCurrentType(booster);
  };

  const { TAP_UPGRADES, CHARGER_UPGRADES, MAXIMIZER_UPGRADES } = UPGRADES;

  function checkBoostAvailability(boost?: {
    available: number;
    last_used_at: number;
  }) {
    if (boost?.last_used_at) {
      // Get the date for today and yesterday
      const now = new Date();
      const yesterday = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1)
      );

      // Set the time to 23:59:59
      yesterday.setUTCHours(23, 59, 59);

      // Convert to UTC timestamps
      const yesterdayTimestamp = yesterday.getTime() / 1000;

      // Check if lastUsedDate is from yesterday
      if (boost?.last_used_at < yesterdayTimestamp) {
        // lastUsedDate is from yesterday
        return payPassMultiplier ?  DAILY_BOOSTERS_TIMES * (payPassMultiplier as number) : DAILY_BOOSTERS_TIMES;
      } else {
        // lastUsedDate is not from yesterday
        return boost.available;
      }
    } else {
      return boost?.available === 0 ? 0 : (DAILY_BOOSTERS_TIMES * (payPassMultiplier as number));
    }
  }

  const availableEnergyBoosts = checkBoostAvailability(
    claimState?.boosts?.full_battery
  );
  const availableDiceBoosts = checkBoostAvailability(
    claimState?.boosts?.lucky_dice
  );

  const availableMultiplierBoosts = () => {
    return (
      typeof claimState?.boosts?.multiplier?.clicks_when_bought === "number"
    );
  };

  const availableWorkerBoosts = () => {
    return (
      typeof claimState?.boosts?.worker?.last_claimed_at === "number"
    );
  };

  const handleUpdateBoosters = async (
    type: EBooster,
    boosts?: { diceValue?: number }
  ) => {

      impactOccurred("heavy");
      let newData: any;
  
      if (type === EBooster.TAP) {
        const clickMultiplierLevel = claimState?.click_multiplier_level;
        const lastIndex = Object.values(TAP_UPGRADES).length - 1;
  
        if (
          claimState &&
          user.database_user &&
          (clickMultiplierLevel || clickMultiplierLevel === 0)
        ) {
          const nextLevel =
            clickMultiplierLevel === 0 ? 1 : clickMultiplierLevel + 1;
  
          newData = {
            id: user.database_user.id,
            click_multiplier_level: nextLevel,
            click_multiplier:
              TAP_UPGRADES[nextLevel]?.upgrade || TAP_UPGRADES[lastIndex].upgrade,
            balance:
              claimState.balance -
              (TAP_UPGRADES[nextLevel]?.price || TAP_UPGRADES[lastIndex].price),
          };
        }
      } else if (type === EBooster.CHARGER) {
        const clickRefillLevel = claimState?.energy_refill_multiplier_level;
        const lastIndex = Object.values(CHARGER_UPGRADES).length - 1;
  
        if (
          claimState &&
          user.database_user &&
          (clickRefillLevel || clickRefillLevel === 0)
        ) {
          const nextLevel = clickRefillLevel === 0 ? 1 : clickRefillLevel + 1;
  
          newData = {
            id: user.database_user.id,
            energy_refill_multiplier_level: nextLevel,
            energy_refill_multiplier:
              CHARGER_UPGRADES[nextLevel]?.upgrade ||
              CHARGER_UPGRADES[lastIndex].upgrade,
            balance:
              claimState.balance -
              (CHARGER_UPGRADES[nextLevel]?.price ||
                CHARGER_UPGRADES[lastIndex].price),
          };
        }
      } else if (type === EBooster.MAXIMIZER) {
        const maxEnergyLevel = claimState?.max_energy_level;
        const lastIndex = Object.values(MAXIMIZER_UPGRADES).length - 1;
  
        if (
          claimState &&
          user.database_user &&
          (maxEnergyLevel || maxEnergyLevel === 0)
        ) {
          const nextLevel = maxEnergyLevel === 0 ? 1 : maxEnergyLevel + 1;
  
          newData = {
            id: user.database_user.id,
            max_energy_level: nextLevel,
            max_energy:
              MAXIMIZER_UPGRADES[nextLevel]?.upgrade ||
              MAXIMIZER_UPGRADES[lastIndex].upgrade,
            balance:
              claimState.balance -
              (MAXIMIZER_UPGRADES[nextLevel]?.price ||
                MAXIMIZER_UPGRADES[lastIndex].price),
          };
        }
      } else if (type === EBooster.FULL_BATTERY) {
        if (claimState && user.database_user && availableEnergyBoosts) {
          const now = new Date();
          const utcTimestampInSeconds = Math.floor(now.getTime() / 1000);
  
          const energyBoost =
            availableEnergyBoosts === 0 ? 0 : availableEnergyBoosts - 1;
  
          const newBoosts = user?.claim_state?.boosts
            ? {
                ...user.claim_state.boosts,
                full_battery: {
                  available: energyBoost,
                  last_used_at: utcTimestampInSeconds,
                },
              }
            : {
                full_battery: {
                  available: energyBoost,
                  last_used_at: utcTimestampInSeconds,
                },
              };
  
          newData = {
            id: user.database_user.id,
            energy: claimState.max_energy,
            boosts: newBoosts,
          };
        }
      } else if (type === EBooster.LUCKY_DICE) {
        if (claimState && user.database_user && boosts?.diceValue) {
          const now = new Date();
          const utcTimestampInSeconds = Math.floor(now.getTime() / 1000);
  
          const diceBoost =
            availableDiceBoosts === 0 ? 0 : availableDiceBoosts - 1;
  
          const newBoosts = user?.claim_state?.boosts
            ? {
                ...user.claim_state.boosts,
                lucky_dice: {
                  available: diceBoost,
                  last_used_at: utcTimestampInSeconds,
                  value: boosts.diceValue,
                  is_used: false,
                },
              }
            : {
                lucky_dice: {
                  available: diceBoost,
                  last_used_at: utcTimestampInSeconds,
                  value: boosts.diceValue,
                  is_used: false,
                },
              };
  
          newData = {
            id: user.database_user.id,
            boosts: newBoosts,
          };
        }
      } else if (type === EBooster.MULTIPLIER) {
        if (claimState && user.database_user) {
          const newBoosts = user?.claim_state?.boosts
            ? {
                ...user.claim_state.boosts,
                multiplier: {
                  clicks_when_bought: user.claim_state.clicks,
                },
              }
            : {
                multiplier: {
                  clicks_when_bought: user!.claim_state!.clicks,
                },
              };
  
          newData = {
            id: user.database_user.id,
            boosts: newBoosts,
            balance:
              claimState.balance - MULTIPLIER_UPGRADES.price,
          };
        }
      } else if (type === EBooster.WORKER) {
        if (claimState && user.database_user) {
          const currentTimestamp = getCurrentTimestamp();
  
          const newBoosts = user?.claim_state?.boosts
            ? {
                ...user.claim_state.boosts,
                worker: {
                  last_claimed_at: currentTimestamp,
                },
              }
            : {
                worker: {
                  last_claimed_at: currentTimestamp,
                },
              };
  
          newData = {
            boosts: newBoosts,
            balance:
              claimState.balance - WORKER_UPGRADES.price,
          };
        }
      }
  
      if (newData && user?.database_user?.id) {
        // TODO: complete
        updateUser({id: user.database_user.id, ...newData })
          .then(() => {
            setClaimState?.((prevState) => ({ ...prevState, ...newData }));
              
            // Close modal
            if (type == EBooster.LUCKY_DICE) {
              setTimeout(() => {
                handleModalClose();
                return navigate(ROUTES.MAIN.PATH);
              }, 1000);
            } else {
              handleModalClose();
            }
  
            if (
              type !== EBooster.FULL_BATTERY &&
              type !== EBooster.LUCKY_DICE &&
              type !== EBooster.MULTIPLIER
            ) {
              setCounter({ start: counter!.end, end: newData.balance });
            }
  
            // redirect
            if (type === EBooster.FULL_BATTERY || type === EBooster.MULTIPLIER) {
              return navigate(ROUTES.MAIN.PATH);
            }
          })
          .catch((e: any) => {
            console.warn(e);
          });
      }
  };

  return {
    currentType,
    onModalClose: handleModalClose,
    onModalOpen: handleModalOpen,
    isLoading,
    isFetching: updateState.isLoading,
    claimState,
    onUpdateBoosters: handleUpdateBoosters,
    counter,
    setCounter,
    availableEnergyBoosts,
    availableDiceBoosts,
    availableMultiplierBoosts: availableMultiplierBoosts(),
    availableWorkerBoosts: availableWorkerBoosts(), 
    onSetRuneStep,
    user,
    payPassMultiplier,
  };
};
