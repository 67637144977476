import React from "react";

import { TemplatePages } from "../template-pages";

import { usePayPassApi } from "./hooks";
import { AccordionComponent } from "./accordion-component";
import { WalletActions } from "./wallet-actions";
import { ErrorNotificationModal } from "./error-notification-modal";
import { SuccessPayPassModal } from "./success-pay-pass-modal";

import "./styles.css";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";
import { ErrorNetModal } from "./error-net-modal";

const bg = require("./img/bg.png") as string;
const galaxy = require("./img/galaxy.svg").default as string;

export const PagePayPass: React.FC = () => {
  const {
    isWalletConnected,
    onSentTransaction,
    showError,
    setShowError,
    isFetching,
    isLoading,
    showSuccessPayPassModal,
    onCloseSuccessPayPassModal,
    onDisconnect,
    address,
    isPayPassPurchased,
    disabled,
    showNetError,
    setShowNetError,
  } = usePayPassApi();

  return <TemplatePages bg={bg} isLoading={isLoading} isFetching={isFetching}>
  <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
  <ErrorNotificationModal
    open={showError}
    onClose={() => setShowError(false)}
    onSentTransaction={onSentTransaction}
  />
    <ErrorNetModal
      open={showNetError}
      onClose={() => setShowNetError(false)}
    />
  <SuccessPayPassModal
    open={showSuccessPayPassModal}
    onClose={onCloseSuccessPayPassModal}
  />
  <div className="pay-pass-root">
    <img src={galaxy} className="pay-pass-logo" alt="" />
    <AccordionComponent isPayPassPurchased={isPayPassPurchased} />
    {!isPayPassPurchased && (
      <WalletActions
        address={address}
        isWalletConnected={isWalletConnected}
        onSentTransaction={onSentTransaction}
        onDisconnect={onDisconnect}
        disabled={disabled}
      />
    )}
  </div>
</TemplatePages>;
};
