export enum EAPITagType {
    USERS = 'USERS',
    UPDATE_USERS = 'UPDATE_USERS',
    LEADERBOARD ='LEADERBOARD',
    VALIDATE_USER_IN_GROUP = 'VALIDATE_USER_IN_GROUP',
    TASKS = 'TASKS',
    STATS = 'STATS',
    MATES_LIST = 'MATES_LIST',
    MATES_GET_REWARDS = 'MATES_GET_REWARDS',
    STAKE_CREATE = 'STAKE_CREATE',
    CLAIM_STAKING = 'CLAIM_STAKING',
    USER_PASSIVE_INCOME = 'USER_PASSIVE_INCOME',
    USER_PASSIVE_INCOME_CLAIM = 'USER_PASSIVE_INCOME',
    USER_PASSIVE_INCOME_INCREASE_LEVEL = 'USER_PASSIVE_INCOME_INCREASE_LEVEL',
    WAI_LEADERBOARD = 'WAI_LEADERBOARD',
    NOTIFY_USERS= 'NOTIFY_USERS',
}