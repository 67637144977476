import React from "react";
import { ROUTES } from "../../routers";

const wallet = require("./img/wallet.png") as string;
const statsIcon = require("./img/stats-icon.png") as string;
const settings = require("./img/settings.png") as string;

export const Header: React.FC = () => {

  return (
    <div className="page-claim-header">
      <p className="green-color ab" style={{ fontSize: "22px" }}>
        W-COIN
      </p>
      <div className="icons page-claim-icons">
      <a href={`#${ROUTES.LEADERBOARD.PATH}`}>
            <img width="22px" height="22px" src={statsIcon} alt="" />
          </a>

        <a href={`#${ROUTES.WALLET.PATH}`}>
          <img width="22px" height="22px" src={wallet} alt="" />
        </a>
        <a href={`#${ROUTES.CHANGE_BLOCKCHAIN.PATH}`}>
          <img width="22px" height="23px" src={settings} alt="" />
        </a>
      </div>
    </div>
  );
};
