import React from "react";

import { usePageClaimApi } from "./hooks";

import "./index.scss";
import { Template } from "../template";
import { ClaimButton } from "./claim-button";
import { ClaimModal } from "./claim-modal";
import { Counter } from "../counter";
import { getClaimIcon } from "../../utils";
import { BottomAnimation } from "../page-animation";
import { Header } from "./header";
import { PagesLinks } from "./pages-links";
import { Footer } from "./footer";

// TODO: ADD AFTER RUNE
// const bg = require("../template/main-bg-choose.png");
// const runeBg = require("./img/rune-loader.png");
// const walletBg = require("../template/walletBg.png") as string;
const waiBg = require("./img/bg-choice.png") as string;

export const PageClaim: React.FC = () => {
  const {
    isLoading,
    isFetching,
    isSuccess,
    energy,
    maxEnergy,
    onClickChange,
    counter,
    disableBtn,
    addToBalance,
    onGoToDashboard,
    workerModalStatus,
    onWorkerClaimBalance,
    onCloseModal,
    earnByMinute,
    claimState,
    activeTaskCount,
    setHasLoadedOnce,
    hasLoadedOnce,
    payPassMultiplier,
  } = usePageClaimApi();

  const canShowWorkerModal = !!(
    workerModalStatus?.readyToShowWorkerModal &&
    !workerModalStatus?.alreadyOpenedModal &&
    earnByMinute
  );

  return (
    <Template
      isLoading={isLoading}
      isMainPage={true}
      isFetching={isFetching}
      setHasLoadedOnce={setHasLoadedOnce}
      hasLoadedOnce={hasLoadedOnce}
      bg={waiBg}
    >
      {canShowWorkerModal && (
        <div
          className={`overlay app-modal-root ${
            canShowWorkerModal ? "blur" : ""
          }`}
        ></div>
      )}
      <ClaimModal
        canShowWorkerModal={canShowWorkerModal}
        value={earnByMinute}
        onClose={onCloseModal}
        onClaim={onWorkerClaimBalance}
        disabled={isLoading}
      />
      {isSuccess && (
        <div className="page-claim">
          <Header />
          <div className="page-claim-content-wrapper">
            <div className="content main-wrapper page-claim-main-wrapper">
              <Counter
                counter={counter}
                additionalComponent={<PagesLinks />}
              />
              <div className="box-claim-wrapper">
                <ClaimButton
                  onClick={onClickChange}
                  energy={energy}
                  maxEnergy={maxEnergy}
                  clickMultiplier={addToBalance}
                  disableBtn={disableBtn}
                  claimIcon={getClaimIcon(claimState?.skins)}
                  isSpinnerSkin={claimState?.skins?.shuriken?.selected}
                  payPassMultiplier={payPassMultiplier}
                />
                <BottomAnimation>
                  <Footer onGoToDashboard={onGoToDashboard} activeTaskCount={activeTaskCount} />
                </BottomAnimation>
              </div>
            </div>
          </div>
        </div>
      )}
    </Template>
  );
};
