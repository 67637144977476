import React from "react";

import { CSSTransition } from "react-transition-group";

import "../index.scss";
import { ETask, ITask } from "../../page-tasks/hooks";
import { VerifyButton } from "../../page-tasks/verify-button";
import { VerifyReceived } from "../../page-tasks/verify-received";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../consts";
import { formatValues } from "../../../utils";
import { TTelegramGroupVerify } from "../../contexts/types";
import { useOpenTelegramLink } from "../../hooks";
import { PayPassMultiplierComponent } from "../../page-tasks/pay-pass-multiplier";

interface IProps {
  currentTask: ITask | null;
  onClose: () => void;
  onClaim?: () => void;
  isJoinedToTelegram?: (type?: ETask | string) => boolean | undefined;
  onTelegramGroupVerify?: TTelegramGroupVerify;
  payPassMultiplier?: number;
}

export const TaskModal: React.FC<IProps> = ({
  currentTask,
  onClose,
  onClaim,
  isJoinedToTelegram,
  onTelegramGroupVerify,
  payPassMultiplier,
}) => {
  const { onOpenTelegramLink } = useOpenTelegramLink();

  const getIcon = () => {
    if (currentTask?.stringIcon) {
      return <div style={{ fontSize: 108 }}>{currentTask.stringIcon}</div>;
    } else if (currentTask?.icon) {
      return <img src={currentTask?.icon} alt="" />;
    }

    return currentTask?.componentIcon;
  };

  const onVerify = () => {
    if (currentTask) {
      return currentTask.type === ETask.TELEGRAM
        ? isJoinedToTelegram?.()
        : isJoinedToTelegram?.(currentTask.type);
    } else {
      return false;
    }
  };

  return (
    <>
      {!!currentTask && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="close-modal ab" onClick={onClose}>
              x
            </div>
            <div className="app-modal-content task-modal-content">
              <div className="task-modal-desc">
                <div className="task-modal-icon">{getIcon()}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    textAlign: "center",
                  }}
                >
                  {currentTask?.verifyLink ? (
                    <VerifyReceived
                      currentTask={currentTask}
                      isJoinedToTelegram={onVerify()}
                      payPassMultiplier={payPassMultiplier}
                    />
                  ) : (
                    <>
                      {
                        <>
                          <div className="task-modal-reward ab">
                            <SmallCoin
                              width={BALANCE_COIN_SIZE_MEDIUM}
                              height={BALANCE_COIN_SIZE_MEDIUM}
                            />
                            <p>+{formatValues(currentTask?.reward)}</p>  <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
                          </div>
                          <p className="task-modal-received">Received</p>
                        </>
                      }
                    </>
                  )}
                </div>
              </div>
              <div className="task-modal-button-wrapper">
                {currentTask?.refLink || currentTask?.verifyLink ? (
                  <VerifyButton
                    onTelegramGroupVerify={onTelegramGroupVerify}
                    currentTask={currentTask}
                    onClaim={onClaim}
                    isJoinedToTelegram={onVerify()}
                  />
                ) : (
                  <button
                    onClick={() => onOpenTelegramLink()}
                    className={`app-modal-content-button`}
                  >
                    Follow W-Community
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
