import React from "react";

import { CSSTransition } from "react-transition-group";
import "../../app-modal/index.scss";
import "./styles.scss";
import { Oval } from "react-loader-spinner";
import { ITask } from "../hooks";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM, TELEGRAM_LINK } from "../../../consts";
import { formatValues } from "../../../utils";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { PayPassMultiplierComponent } from "../pay-pass-multiplier";

interface IProps {
  currentTask: ITask | null;
  onClose: () => void;
  onClaim: () => Promise<void>;
  isLoading?: boolean;
  showVerifyModal: boolean;
  payPassMultiplier?: number;
}

export const VerifyModal: React.FC<IProps> = ({
  currentTask,
  onClose,
  onClaim,
  isLoading,
  showVerifyModal,
  payPassMultiplier,
}) => {
  const webApp = useWebApp();

  const [activeTaskType, setActiveTaskType] = React.useState<{
    type: string;
    check: number;
  } | null>();

  React.useEffect(() => {
    if (currentTask && !activeTaskType?.type) {
      setActiveTaskType({ type: currentTask.type, check: 1 });
    }
  }, [currentTask]);

  const handleClose = () => {
    onClose();
    setActiveTaskType(null);
  };

  const handleVerify = () => {
    if (activeTaskType?.type) {
      try {
        setActiveTaskType((prevState) => ({
          type: activeTaskType.type,
          check: (activeTaskType.check || 0) + 1,
        }));
      } catch (e) {}
    }
  };

  const handleOpenLink = () => {
    if (activeTaskType?.check === 5) {
      webApp.openTelegramLink(TELEGRAM_LINK);
      return;
    }
    if (activeTaskType?.type) {
      try {
        if (currentTask?.hasTelegramLink) {
          
          if (currentTask.verifyLink) {
            webApp.openTelegramLink(`https://t.me/${currentTask.verifyLink}`);
          }

          webApp.openTelegramLink(currentTask?.link);


        } else {
          webApp.openLink(currentTask?.link);
        }

        setActiveTaskType((prevState) => ({
          type: activeTaskType.type,
          check: (prevState?.check || 0) + 1,
        }));
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const getIcon = () => {
    return (
      <div className="task-modal-icon">
        <img src={currentTask?.icon} alt="" />
      </div>
    );
  };

  const verifyTimes = activeTaskType?.check || 0;

  const handleClaim = () => {
    setActiveTaskType((prevState) =>
      prevState ? { ...prevState, check: prevState.check + 1 } : null
    );
    onClaim();
  };

  const getTip = () => {
    if (verifyTimes === 1) {
      return (
        <p className="task-modal-received">
          {currentTask?.modalLabel ?? "Complete task to get"}
        </p>
      );
    } else if (verifyTimes === 3) {
      return (
        <p className="task-modal-received red-color">
          Check again if you have joined
        </p>
      );
    } else if (verifyTimes === 5) {
      return <p className="task-modal-received">Received</p>;
    }
  };

  const getButton = () => {
    if (verifyTimes === 2) {
      return (
        <button
          onClick={handleVerify}
          className={`app-modal-content-button collab-modal-button`}
        >
          Verify
        </button>
      );
    }
    if (verifyTimes === 4) {
      return (
        <button
          onClick={handleClaim}
          className={`app-modal-content-button collab-modal-button`}
        >
          Claim
        </button>
      );
    } else if (verifyTimes === 1 || verifyTimes === 3) {
      return (
        <button
          onClick={handleOpenLink}
          className={`app-modal-content-button collab-modal-button`}
        >
          {currentTask?.title}
        </button>
      );
    } else if (verifyTimes === 5) {
      return (
        <button
          onClick={handleOpenLink}
          className={`app-modal-content-button collab-modal-button`}
        >
          Follow W-Community
        </button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {!!currentTask && showVerifyModal && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask && showVerifyModal}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            style={{ position: "relative" }}
            onClick={(e) => e.stopPropagation()}
          >
            {isLoading && (
              <div className="app-spinner">
                <Oval
                  visible={true}
                  height="100"
                  width="100"
                  color="#9747FF"
                  secondaryColor="rgb(225, 219, 239)"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            <div className="close-modal ab" onClick={handleClose}>
              x
            </div>
            <div className="app-modal-content task-modal-content">
              <div className="task-modal-desc">
                {getIcon()}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {
                    <>
                      {getTip()}
                      <div className="task-modal-reward ab">
                        <SmallCoin
                          width={BALANCE_COIN_SIZE_MEDIUM}
                          height={BALANCE_COIN_SIZE_MEDIUM}
                        />
                        <p>+{formatValues(currentTask?.reward)}</p> <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
                      </div>
                    </>
                  }
                </div>
              </div>
              <div className="task-modal-button-wrapper">{getButton()}</div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
