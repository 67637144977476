import {
    TUpdateClientArgs,
    useUpdateUserMutation,
  } from './components/feature/users';
  import { encryptData } from './utils';
  
  export const useUpdateUser = () => {
    const [updateUser, updateState] = useUpdateUserMutation();
  
    async function updateUserAsync(data: TUpdateClientArgs) {
      const result = await encryptData(data);
  
      if (result) {
        const { encryptedData, encryptedSymmetricKey, iv, authTag } = result;
        return updateUser({
          encryptedData,
          encryptedSymmetricKey,
          iv,
          data,
          authTag,
        }).unwrap();
      }
    }
  
    return [updateUserAsync, updateState as any];
  };

  // export const useShowContentApi = () => {
  //   // @ts-ignore
  //   const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  //   const canShowContent = (id?: number) => {
  //     // const currentId = id || process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_EMAIL;
    
  //     // const USER_IDS = process.env.REACT_APP_TEST_USER_IDS;
  //     const USER_IDS = [];
    
  //     if (USER_IDS && id) {
  //       // return USER_IDS.split(', ').includes(String(currentId));
  //       return USER_IDS.includes(id);
    
  //     }
    
  //     return false;
  //   }

  //   return {
  //     canShowContent: canShowContent(id),
  //   }
  // }