import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { UserContext } from "../contexts/user-context";
import {
  TMate,
  TTask,
  useGetMatesQuery,
  useLazyGetMatesGetRewardsQuery,
} from "../feature/users";
import { TClaim } from "../contexts/types";
import { useUpdateUser } from "../../hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { useLocation } from "react-router-dom";

export const INVITE_MATE_URL = process.env.REACT_APP_INVITE_MATE_URL;

export const INVITE_THREE_MATES_REWARD = 500_000;

export type TLevelData = {
  level: number;
  reward: {
    old: number,
  };
  premiumReward: {
    old: number,
  };
  mates: number;
  rewards: number;
  levelRewards: {
    old: number,
  };
};

export type TLevel = {
  [level: number]: TLevelData;
};

export type TClaims = {
  rewards: TClaim;
  forUpdate: TClaim;
};

export type TMappedTMate = TMate & { reward: number };

export const LEVELS: TLevel = {
  1: {
    level: 1,
    reward: {
      old: 2_500,
    },
    premiumReward: {
      old: 30_000,
    },
    mates: 10,
    rewards: 1,
    levelRewards: {
      old:  100_000,
    },
  },
  2: {
    level: 2,
    reward: {
      old: 5_000,
    },
    premiumReward: {
      old: 50_000,
    },
    mates: 35,
    rewards: 1.5,
    levelRewards: {
      old: 200_000,
    },
  },
  3: {
    level: 3,
    reward: {
      old: 7_500,
    },
    premiumReward: {
      old: 75_000,
    },
    mates: 75,
    rewards: 2,
    levelRewards: {
      old: 400_000,
    },
  },
  4: {
    level: 4,
    reward: {
      old: 10_000,
    },
    premiumReward: {
      old: 100_000,
    },
    mates: 150,
    rewards: 2.5,
    levelRewards: {
      old: 800_000,
    },
  },
  5: {
    level: 5,
    reward: {
      old: 12_500,
    },
    premiumReward: {
      old: 125_000,
    },
    mates: 300,
    rewards: 3,
    levelRewards: {
      old: 1_600_000,
    },
  },
  6: {
    level: 6,
    reward: {
      old: 15_000,
    },
    premiumReward: {
      old: 150_000,
    },
    mates: 600,
    rewards: 3.5,
    levelRewards: {
      old: 3_200_000,
    },
  },
  7: {
    level: 7,
    reward: {
      old: 17_500,
    },
    premiumReward: {
      old: 175_000,
    },
    mates: 1_200,
    rewards: 4,
    levelRewards: {
      old: 6_400_000,
    },
  },
  8: {
    level: 8,
    reward: {
      old: 20_000,
    },
    premiumReward: {
      old: 200_000,
    },
    mates: 2_400,
    rewards: 4.5,
    levelRewards: {
      old: 12_800_000,
    },
  },
  9: {
    level: 9,
    reward: {
      old: 22_500,
    },
    premiumReward: {
      old: 225_000, 
    },
    mates: 4800,
    rewards: 5,
    levelRewards: {
      old: 25_600_000,
    },
  },
  10: {
    level: 10,
    reward: {
      old: 25_000,
    },
    premiumReward: {
      old: 250_000,
    },
    mates: 8_000,
    rewards: 5.5,
    levelRewards: {
      old: 51_200_000,
    },
  },
  11: {
    level: 11,
    reward: {
      old: 27_000,
    },
    premiumReward: {
      old: 275_000,
    },
    mates: 16_000,
    rewards: 6,
    levelRewards: {
      old: 102_400_000,
    },
  },
};

const FIRST_LEVEL = LEVELS[0];
const LAST_LEVEL = Object.values(LEVELS).at(-1)?.level;

export const usePageMatesApi = () => {
  const webApp = useWebApp();
  const [impactOccurred] = useHapticFeedback();
  const [openModal, setOpenModal] = React.useState<number | null>(null);

  const {
    user,
    isLoading: isContextLoading,
    setClaimState,
    isSuccess,
  } = React.useContext(UserContext);

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;

  const canGetMates = isSuccess ? undefined : skipToken;

  // Queries
  const { data: mates, isLoading: isMatesLoading } = useGetMatesQuery(
    canGetMates,
    {
      selectFromResult: (result) => {
        return {
          ...result,
          newMates: result?.currentData,
        };
      },
    }
  );

  const [updateUser, updateUserState] = useUpdateUser();
  const [fetchMatesGetRewards, state] = useLazyGetMatesGetRewardsQuery();

  React.useEffect(() => {
    if (mates && !user?.claim_state?.newMates) {
      // @ts-ignore
      setClaimState?.((prevState) => ({
        ...(prevState || {}),
        newMates: mates,
      }));
    }
  }, [mates, setClaimState, user?.claim_state?.newMates]);

  const currentLevelParams = user?.claim_state?.current_mates_level
    ? LEVELS[Number(user?.claim_state.current_mates_level)]
    : FIRST_LEVEL;

  const isLastLevel = !!(
    user?.claim_state?.current_mates_level &&
    user.claim_state.current_mates_level === LAST_LEVEL
  );

  const handleUpdateMatesLevel = () => {
    setOpenModal(currentLevelParams.levelRewards.old);
    impactOccurred("heavy");

    if (
      user.claim_state?.current_mates_level &&
      user.database_user?.id &&
      currentLevelParams && payPassMultiplier
    ) {
      const current_mates_level = isLastLevel
        ? user.claim_state.current_mates_level
        : Number(user.claim_state.current_mates_level) + 1;

      const newData = {
        current_mates_level,
        balance:
          Number(user.claim_state.balance) +  (currentLevelParams.levelRewards.old * payPassMultiplier),
        balance_from_clicks:
          Number(user.claim_state.balance_from_clicks) +
          (currentLevelParams.levelRewards.old  * payPassMultiplier),
      };

      // TODO: completed
      updateUser({
        id: user.database_user.id,
        ...newData,
      })
        .then(() => {
          // @ts-ignore
          setClaimState?.((prevState) => ({ ...prevState, ...newData }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  const handleGetClaimAll = () => {
    impactOccurred("heavy");
    fetchMatesGetRewards()
      .then((res) => {
        if (res.data && res.data.new_balance) {
          // @ts-ignore
          setClaimState?.((prevState) => ({
            ...(prevState || {}),
            balance: res!.data!.new_balance,
            balance_from_clicks:
              (prevState!.balance_from_clicks || 0) + res?.data!.reward,
            newMates: {
              ...prevState!.newMates,
              total_reward_balance: 0,
              top_reward_mates: prevState!.newMates!.top_reward_mates!.map(
                (mate) => ({
                  ...mate,
                  reward: 0,
                })
              ),
            },
          }));
          setOpenModal(res.data.reward);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleInviteMate = () => {
    webApp.openTelegramLink(INVITE_MATE_URL);
    webApp.close();
  };

  return {
    isLoading: isMatesLoading || isContextLoading,
    isFetching: updateUserState.isLoading || state.isFetching,
    mates: user?.claim_state?.newMates,
    user,
    onUpdateMatesLevel: handleUpdateMatesLevel,
    onGetClaimAll: handleGetClaimAll,
    isLastLevel,
    currentLevelParams,
    onInviteMate: handleInviteMate,
    onModalClose: () => setOpenModal(null),
    isOpenModal: openModal,
    payPassMultiplier,
  };
};

export const useCheckTaskTheeFriendsApi = (mates?: number) => {
  const [updateUser, updateUserState] = useUpdateUser();

  const location = useLocation();
  const {user, setClaimState } = React.useContext(UserContext);
  const [verifyModalOpen, setVerifyModalOpen] = React.useState(false);
  const [inviteMateCount, setInviteMateCount] = React.useState<number | undefined>(undefined);

  const [isVerify, setIsVerify] = React.useState<undefined | boolean>(
    undefined
  );



  React.useEffect(() => {
    if (
      location?.state?.inviteThreeMatesData &&
      typeof mates === "number"
    ) {
      if (location?.state?.inviteThreeMatesData?.completed) {
        setIsVerify(true);
        setVerifyModalOpen(false);
      } else {
        // @ts-ignore
         if (!user?.claim_state?.tasks?.inviteThreeMatesData?.completed) {
          setVerifyModalOpen(true);
         }

      }
    }
  }, [location?.state?.inviteThreeMatesData, mates, user?.claim_state]);

  const handleVerify = () => {
    const payPassMultiplier = user?.claim_state?.payPassMultiplier;

    if (typeof mates === "number" && payPassMultiplier) {

      const tasks = inviteMates(
        mates || 0,
        // @ts-ignore
        user?.claim_state?.tasks
      );
      // @ts-ignore
      setInviteMateCount(tasks?.inviteThreeMatesData?.remainingInvites);
      // @ts-ignore
      const newData = {
        // @ts-ignore
        balance: tasks?.inviteThreeMatesData?.completed
          ? user!.claim_state!.balance + (INVITE_THREE_MATES_REWARD * payPassMultiplier)
          : user!.claim_state!.balance,
        tasks,
      };

      if (user?.database_user?.id) {
        updateUser({
          id: user.database_user.id,
          ...newData,
        })
          .then(() => {
            // @ts-ignore
            setClaimState?.((prevState) => ({ ...prevState, ...newData }));

            // @ts-ignore
            if (tasks?.inviteThreeMatesData?.completed) {
              setIsVerify(true);
            } else {
              setIsVerify(false);
            }
          })
          .catch((e: any) => {
            console.warn(e);
          });
      }
    }
  };

  return {
    isVerify,
    verifyModalOpen,
    onCloseVerifyModalOpen: () => setVerifyModalOpen(false),
    onVerify: handleVerify,
    isFetching: updateUserState.isLoading,
    inviteMateCount,

  };
};

export function inviteMates(mates: number, tasks?: TTask): TTask {
  if (!tasks) {
    return {
      inviteThreeMatesData: {
        mates: mates,
        isEnoughMates: false,
        completed: false,
        remainingInvites: Math.max(3 - mates, 0),
      },
    };
  }

  const inviteThreeMatesData = tasks?.inviteThreeMatesData;
  // @ts-ignore
  const initialMates = inviteThreeMatesData?.mates ?? mates;
  const currentMates = mates;
  const isEnoughMates = currentMates - initialMates >= 3;
  const remainingInvites = Math.max(3 - (currentMates - initialMates), 0);

  // @ts-ignore
  if (inviteThreeMatesData?.completed) {
    return tasks;
  }

  return {
    ...tasks,
    inviteThreeMatesData: {
      mates: initialMates,
      isEnoughMates: isEnoughMates,
      completed: isEnoughMates,
      remainingInvites: remainingInvites,
    },
  };
}
