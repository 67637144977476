import React from "react";

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import "./styles.css";
import { SUPPORT_LINK } from "../../../consts";
const cross = require("./cross.png") as string;

interface IProps {
  open: boolean;
  onClose: () => void;
  onSentTransaction: () => Promise<void>;
}

export const ErrorNotificationModal: React.FC<IProps> = ({
  open,
  onClose,
  onSentTransaction,
}) => {
  const webApp = useWebApp();
  const handleOpenSupport = () => {
    webApp.openTelegramLink(SUPPORT_LINK);
  };

  return (
    <CSSTransition in={open} timeout={500} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root app-pay-pass-error-notification-root blur">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper app-pay-pass-error-notification-wrapper`}
          style={{ position: "relative" }}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            width="20px"
            className="close-modal close-modal-error-notification"
            src={cross}
            alt=""
            onClick={onClose}
          />
          <div className="error-notification-info-wrapper">
            <div className="error-notification-heading-wrapper">
              <p className="error-notification-heading ab">
                Transaction Failed
              </p>
              <p className="error-notification-sub-heading">
                Please, try again or
                <p
                  className="error-notification-sub-heading-support"
                  onClick={handleOpenSupport}
                >
                  contact us
                </p>
              </p>
            </div>
            <button
              onClick={() => {
                onSentTransaction();
                onClose();
              }}
              className="error-notification-action ab"
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
